import logo from './logo.svg';
import Main from './components/main/main'
import DummyPage from './components/dummyPage/dummyPage'
import {Routes,Route} from 'react-router-dom'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
         <Route exact  path="/" element={<Main/>}/>
         <Route path="/:data" element={<Main/>}/>
       
         {/* <Route path="/" element={<DummyPage/>}/> */}
        </Routes>
      </header>
    </div>
  );
}

export default App;
