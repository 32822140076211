import React from 'react'
import './main.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PaymentPage from './paymentPage/paymentPage'
import Navbar from './navbar/navbar'
export default function Main() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const status=window?.location?.pathname.substr(1,window?.location?.pathname.length)?JSON.parse(atob(window?.location?.pathname.substr(1,window?.location?.pathname.length))):""


  const [callBackMessage,setCallBackMessage]=React.useState("Waiting")
  React.useEffect(() => {
  
    if(status.value==="success")
    {
      
     setTimeout(() => {
      handleOpen()
       setCallBackMessage("Application Granted, Check Email for Invoice")
     }, 1000);
      setTimeout(() => {
        handleClose()
      }, 4500);
    }
    else if(status.value==="NMD")
    {
      setTimeout(() => {
      handleOpen()
        
        setCallBackMessage("Need More Details, Application Under Review")
      }, 1000);
       setTimeout(() => {
        handleClose()
      }, 4500);

    }
    else if(status.value==="failure")
    {
      setTimeout(() => {
      handleOpen()
        
        setCallBackMessage("Failed to grant loan, contact us")
      }, 1000);
      setTimeout(() => {
        handleClose()
      }, 4500);
    }
    
  }, [])
  


  return (
    <>
    <div className="mainpage">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ backgroundColor:'white',justifyContent: 'center',alignItems: 'center', width: 600,height:200 ,border:'none',display:'flex',position: 'absolute',top: '40%',left:'35%',padding:'1rem',borderRadius:'10px'}}>
          <h2 id="parent-modal-title">{callBackMessage}</h2>
         
          
        </Box>
      </Modal>
      <div style={{marginTop:'-48%'}}>
        <Navbar/>
      </div>
      <div className="container">
      <p className="title-head">Excel Digital Marketing Institute</p>
      <p className="title-text">
        Course: Marketing in a Digital World
      </p>
      <p className="title-text">
         <ul style={{textAlign:'left'}}>
        Join 200,000+ students in the bestselling digital marketing course on Excel Digital Marketing Institute!



        With over 20 hours of training, quizzes and practical steps you can follow - this is one of the most comprehensive digital marketing courses available. We'll cover SEO, YouTube Marketing, Facebook Marketing, Google Adwords, Google Analytics and more!



        What you'll learn

        Grow a Business Online From Scratch
        Make Money as an Affiliate Marketer
        Land a High-Paying Job in Digital Marketing
        Work From Home as a Freelance Marketer


        Requirements

        No experience required
        Suitable for all types of businesses (digital product, physical product, service, B2B, B2C).


        You'll Also Get:
        </ul>

      <ul style={{textAlign:'left'}}>

        <li>✔ Lifetime Access to course updates</li>



        <li>✔ Fast & Friendly Support in the Q&A section</li>




        <li>✔ Certificate of Completion Ready for Download</li>

      </ul>
      </p>
      <p className="title-text">
        Contact Us:
         <ul style={{textAlign:'left'}}>
        <li>support@exceldigitalmarketing.com</li>
        <li>1800 000 000</li>
        </ul>

        Terms & Conditions:
         <ul style={{textAlign:'left'}}>
       <li> This is a sample fee collection Payment Page created by Razorpay. Please note that you will not be getting actual course registration post a payment. 

        The payment made will be refunded.
              
        You agree to share information entered on this page with Excel Digital Marketing Institute (owner of this page) and Razorpay, adhering to applicable laws.
      </li>
        </ul>
      </p>
     
      </div>
      <PaymentPage/>
    </div>
    </>
  )
}
